// 格式化css
import 'normalize.css/normalize.css';
// 引用jquery
import './lib/import_jquery';
// swiper
import Swiper from 'swiper';
// import 'swiper/swiper.css';
// icon引用
import './assets/iconfont/iconfont.js';

import { bannerList, communicationImgList, resourcesImgList, serviceImgList, systemImgList } from './config/imgList';



$(document).ready(function () {
  // 初始化轮播
  initSwiper()
  initDigital()
  // 数字化资源三个banner监听点击
  digitalListen()
  // 添加标题吸顶效果
  window.addEventListener('scroll', function () {
    let t = $('body, html').scrollTop() // body的滚动条距离
    if (t > 0) {
      $('.header-fix').addClass('header-active')
      $('.company-header').addClass('header-active')
    } else {
      $('.header-fix').removeClass('header-active')
      $('.company-header').removeClass('header-active')
    }
  })

  // 全局监听点击事件
  window.addEventListener('click', function () {
    if ($('.draw-grid-border')[0].style.display === 'block') {
      $('.draw-grid-border').hide()
    }
  })
  // 取消冒泡
  $('#drawerButton').click(function (e) {
    e.stopPropagation()
    $('.draw-grid-border').show()
  })

  // 标题
  $('.nav-list li').click(function () {
    $(this).siblings().removeClass("active");
    $(this).addClass("active");
  })

  // 系统展示激活
  $('.navlist li').click(function () {
    $(".navlist li").removeClass("active");
    $(this).addClass("active");
  })
})
/**
 * @description 初始化轮播
 */
function initSwiper() {
  // saper 轮播
  // var swiper2 = new Swiper('.swiper-container2', {
  //   slidesPerView: 3,
  //   spaceBetween: 10,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev',
  //   },
  // });
  var swiper = new Swiper('.swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  var swiper3 = new Swiper('.min-swiper-container', {
    pagination: {
      el: '.swiper-pagination',
    },
  });
}
/**
 * @description 设置部分默认图
 */
function initDigital() {
  // 设置资源默认图
  $('#sourceRight img').attr('src', resourcesImgList[0])
  $('#commuicationRight img').attr('src', communicationImgList[0])
  $('#serviceRight img').attr('src', serviceImgList[0])
  $('#systemRight img').attr('src', systemImgList[0])
  $('#minBanner img').attr('src', bannerList[0])
}

/**
 * @description 数字化资源激活
 */
function digitalListen() {
  $('.rosource-icon-list li').click(function () {

    $(this).siblings().removeClass("active");
    $(this).addClass("active");
    $('#sourceRight img').attr('src', resourcesImgList[$(this).index()])
  })
  $('.communication-icon-list li').click(function () {

    $(this).siblings().removeClass("active");
    $(this).addClass("active");
    $('#commuicationRight img').attr('src', communicationImgList[$(this).index()])
  })
  $('.service-icon-list li').click(function () {

    $(this).siblings().removeClass("active");
    $(this).addClass("active");
    $('#serviceRight img').attr('src', serviceImgList[$(this).index()])
  })
  $('.navlist li').click(function () {

    $(this).siblings().removeClass("active");
    $(this).addClass("active");
    $('#systemRight img').attr('src', systemImgList[$(this).index()])
  })
  $('.action-list li').click(function () {

    $(this).siblings().removeClass("active");
    $(this).addClass("active");
    $('#minBanner img').attr('src', bannerList[$(this).index()])
  })
}
