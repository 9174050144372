import img1 from '../assets/image/banner/自动讲解.png'
import img2 from '../assets/image/banner/图文展示.png'
import img3 from '../assets/image/banner/路线规划.png'
import img4 from '../assets/image/banner/周边信息.png'
import img5 from '../assets/image/banner/全景地图.png'
import img6 from '../assets/image/banner/景区介绍.png'

import img9 from '../assets/image/banner/360旋转.png'
import img10 from '../assets/image/banner/放大缩小.png'
import img11 from '../assets/image/banner/语音讲解.png'
import img12 from '../assets/image/banner/DIY合影.png'
import img13 from '../assets/image/banner/兔.png'
import img14 from '../assets/image/banner/蛇.png'
import img15 from '../assets/image/banner/羊.png'
import img16 from '../assets/image/banner/猴.png'

// Digital communication comprehensive solution
const resourcesImgList = [
  img1, img2, img3, img4
]
const communicationImgList = [
  img5, img6
]
const serviceImgList = [
  img9, img10, img11, img12
]
const systemImgList = [
  img13, img14, img15, img16
]

const bannerList=[
  img1,img5,img10
]
export {
  resourcesImgList, communicationImgList, serviceImgList, systemImgList,bannerList
}